<mat-accordion multi="true" *ngIf="task">
  <esomus-task-panel [fromTask]="true" [showBtns]="!task.closed" [task]="task"></esomus-task-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Formulaire de réalisation
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-form-component *ngIf="task.form" [disabled]="true" [formID]="task.form.id"
                           [forms]="forms"></esomus-form-component>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Portée
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-table *ngIf="scopeTable" [options]="scopeTable"></esomus-table>
  </mat-expansion-panel>
  <esomus-document-table [parent]="task" parentType="task"></esomus-document-table>
  <mat-expansion-panel *ngIf="!task.definition" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Planification
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="!task.closed" class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <button (click)="planification()" type="button" color="success" mat-flat-button>Planifier
          cette tâche
        </button>
      </div>
    </div>
    <esomus-table *ngIf="assigmentTable" [options]="assigmentTable"></esomus-table>
  </mat-expansion-panel>
  <esomus-action-task-table *ngIf="!task.definition" [esomusAutoScroll]="task.generated"
                            [taskID]="task.id" [expanded]="true"
                            [displayAll]="task.status !== 0"></esomus-action-task-table>
</mat-accordion>
