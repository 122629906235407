<mat-accordion multi="true" class="mat-edit" *ngIf="role">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Role : {{role.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT"
                            [control]="entityForm.get('description')"
                            i18n-label="@@description" label="Description" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-input [type]="fieldType.NUMBER"
                            [control]="entityForm.get('type')"
                            i18n-label="@@type" label="Type" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-ng-select *ngIf="taskTypeOptions && entityForm.get('taskType')"
                                [options]="taskTypeOptions"
                                [control]="entityForm.get('taskType')" fxFlex="100" flex="100"
                                label="Type d'obligation"></esomus-ng-select>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div class="btn-group-left" fxFlex="50">
                <esomus-delete-button *ngIf="role.id && role.active"
                                      [url]="getRoleDeleteURL()"
                                      [successUrl]="getRoleSuccessURL()"></esomus-delete-button>
              </div>
              <div class="btn-group-right" fxFlex="50">
                <esomus-back-button></esomus-back-button>
                <esomus-submit (submit)="submit()"></esomus-submit>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
