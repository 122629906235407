<form>
  <mat-accordion multi="true" class="mat-edit">
    <mat-expansion-panel expanded="true" *ngIf="programming">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Programmation : {{programming.id ? 'Edition' : 'Création'}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-ng-select *ngIf="personOptions" [options]="personOptions"
                                [control]="entityForm.get('person')"
                                i18n-label="@@definedBy" label="Defini par" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('description')"
                            i18n-label="@@description" label="Description" flex="100"
                            fxFlex="100" [autofocus]="true"></esomus-input>
              <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('pivotDate')"
                            i18n-label="@@pivotDate" label="Date pivot" flex="50"
                            fxFlex="100"></esomus-input>
              <mat-checkbox [formControl]="entityForm.get('forceDay')"
                            i18n-label="@@forceDay" label="Forcer jour" fxFlex="50">Forcer jour
              </mat-checkbox>
              <esomus-input [type]="fieldType.NUMBER" [control]="entityForm.get('frequence')"
                            i18n-label="@@frequence" label="Tous les" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-ng-select *ngIf="unitOptions" [options]="unitOptions"
                                [control]="entityForm.get('unit')"
                                i18n-label="@@unit" label="Unité de fréquence" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-input [type]="fieldType.NUMBER" [control]="entityForm.get('anticipation')"
                            i18n-label="@@anticipation" label="Période d'anticipation" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-ng-select *ngIf="taskOptions" [options]="taskOptions"
                                [control]="entityForm.get('task')"
                                i18n-label="@@task" label="Tâche" fxFlex="50"
                                flex="100"></esomus-ng-select>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="btn-group-right">
    <esomus-back-button></esomus-back-button>
    <esomus-submit (submit)="submit()"></esomus-submit>
  </div>
</form>
