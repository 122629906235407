<mat-accordion multi="true" class="mat-edit" *ngIf="accidentType">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Type d'accident: {{accidentType.id ? 'Edition' : 'Création'}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT"
                            [control]="entityForm.get('description')"
                            i18n-label="@@description" label="Description" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-input [type]="fieldType.TEXT"
                            [control]="entityForm.get('group')"
                            i18n-label="@@group" label="Groupe" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-ng-select *ngIf="entityForm.get('form')" [options]="formOptions"
                                [control]="entityForm.get('form')" i18n-label="@@form"
                                label="Formulaire" fxFlex="100" flex="100"></esomus-ng-select>
              <esomus-line-data *ngIf="!entityForm.get('form')" fxFlex="100" flex="100"
                                boldValue="Formulaire"
                                [textValue]="accidentType.form.name"></esomus-line-data>
            </div>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div class="btn-group-left" fxFlex="50">
                <esomus-delete-button *ngIf="accidentType.id && accidentType.active"
                                      [url]="getAccidentTypeDeleteURL()"
                                      [successUrl]="getAccidentTypeSuccessURL()">
                </esomus-delete-button>
              </div>
              <div class="btn-group-right" fxFlex="50">
                <esomus-back-button></esomus-back-button>
                <esomus-submit (submit)="submit()"></esomus-submit>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
