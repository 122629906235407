export const environment = {
  production: true,
  appName: 'Esomus [SCHINDLER]',
  languages: ['fr', 'nl'],
  api: {
    domain: 'api.schindler.esomus.com',
    url: 'https://api.schindler.esomus.com/api'
  },
  version: '14/11/2019 13:16'
};
