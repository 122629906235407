import { ChangeDetectorRef, Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActivatedRoute, Params } from '@angular/router';
import { PersonService } from '../../../core/api/person.service';
import { ItemDescriptionService } from '../../../core/api/item-description.service';
import { Person } from '../../../core/models/person.model';
import { TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { Entity } from '../../../core/models/entity.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { of } from 'rxjs';

@Component({
  selector: 'esomus-person-view',
  templateUrl: './person-view.component.html',
  styleUrls: ['./person-view.component.sass']
})
export class PersonViewComponent implements OnInit {
  person: Person;

  isPerson: boolean;
  isCompany: boolean;
  isGroup: boolean;

  companyTableOptions: TableOptions;
  nbCompanies: number;
  memberTableOptions: TableOptions;
  nbMembers: number;
  memberTableEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('formModal', { static: true }) formModal: TemplateRef<any>;
  formModalRef: MatDialogRef<PersonViewComponent>;
  memberForm: FormGroup;
  memberOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private dialog: MatDialog,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private personService: PersonService,
    private itemDescriptionService: ItemDescriptionService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    const personID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getPerson(personID);
  }

  private _getPerson(id: number) {
    this.personService.find(id, '', { origin: 'person' }).subscribe((person: Person) => {
      this.person = person;

      this.isPerson = this.person.type === 0;
      this.isCompany = this.person.type === 1;
      this.isGroup = this.person.type === 2;

      if (!this.isPerson) {
        let addBtnLabel;
        let personOpts;
        if (this.isCompany) {
          addBtnLabel = 'Lier un employé';
          personOpts = { companyID: this.person.id };
        } else if (this.isGroup) {
          addBtnLabel = 'Lier un membre';
          personOpts = { groupID: this.person.id };
        }

        this.memberTableOptions = {
          columnDefs: [
            {
              prop: 'firstName',
              name: this.i18n({ value: 'Prénom', id: 'firstName' })
            },
            {
              prop: 'lastName',
              name: this.i18n({ value: 'Nom', id: 'lastName' })
            },
          ],
          findDataCb: (searchData: Params) => this.personService.getMembers(this.person.id),
          actions: {
            create: [
              {
                btnName: addBtnLabel,
                createCb: () => {
                  this.formModalRef = this.dialog.open(this.formModal);

                  this.memberForm = this.fb.group({
                    member: [null]
                  });
                  this.memberOptions = {
                    get: () => this.personService.findAll('', personOpts),
                    propName: 'fullName'
                  };

                  this.cd.detectChanges();
                }
              },
            ],
            readCb: (entity: Entity) => this.routeNameService.goTo('person_view', { id: entity.id }),
            custom: [
              {
                icon: 'link_off',
                customCb: (entity: Entity) => this.unlinkMember(entity.id)
              }
            ]
          }
        };
      } else {
        if (!this.person.companies) {
          this.person.companies = [];
        }
        this.companyTableOptions = {
          columnDefs: [
            {
              prop: 'internalNumber1',
              name: this.i18n({ value: 'Numéro interne 1', id: 'internalnumber1' })
            },
            {
              prop: 'lastName',
              name: this.i18n({ value: 'Nom', id: 'lastname' })
            },
            {
              prop: 'title',
              name: this.i18n({ value: 'Titre', id: 'title' })
            },
          ],
          findDataCb: (searchData: Params) => of(this.person.companies),
          actions: {
            readCb: (entity: Entity) => this.routeNameService.goTo('person_view', { id: entity.id }),
          }
        };
      }

      this.cd.detectChanges();
    });
  }

  editPerson() {
    this.routeNameService.goTo('person_id', { id: this.person.id });
  }

  updateNbMembers(nbMembers: number) {
    this.nbMembers = nbMembers;
  }

  updateNbCompanies(nbCompanies: number) {
    this.nbCompanies = nbCompanies;
  }

  linkMember() {
    let memberID = this.memberForm.get('member').value;

    if (memberID === null) {
      return;
    }

    this.closeMemberModal();

    this.personService.postMember(this.person.id, memberID).subscribe((entity: Entity) => {
      this.memberTableEmitter.emit();
    });
  }

  closeMemberModal() {
    this.formModalRef.close();
    this.formModalRef = null;
  }

  unlinkMember(memberID: number) {
    this.personService.deleteMember(this.person.id, memberID).subscribe(() => {
      this.memberTableEmitter.emit();
    });
  }
}
