<ng-container *ngIf="equipment">
  <h1 mat-dialog-title>
    Changement du status
  </h1>
  <form (submit)="submit()">
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <esomus-ng-select *ngIf="statusOptions" [options]="statusOptions"
                          [control]="entityForm.get('status')"
                          i18n-label="@@status" label="Statut" fxFlex="100"
                          flex="100"></esomus-ng-select>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit label="Changer"></esomus-submit>
      <button type="button" mat-stroked-button mat-dialog-close i18n="@@cancel">
        Annuler
      </button>
    </div>
  </form>
</ng-container>
